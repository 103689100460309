import { shuffle, findWordCombinations } from './helpers';
import Grid from './Grid';
import { Exception } from 'sass';

export default class GameEngine {
    constructor(cols, rows, words) {
        this.healthy = true;

        // Set up the grid
        this.cols = cols;
        this.rows = rows;
        this.grid = new Grid(cols, rows);

        // Make sure words are uppercase
        words = words.map((word) => word.toUpperCase());

        // Place the first word
        this.firstWord = words.shift();
        if (!this.placeFirstWord(this.firstWord)) {
            console.log('unhealthy because placefirstword is falsey');
            this.healthy = false;
        }
        this.words = [this.firstWord];

        // Place remaining words
        if (!this.placeWords(words)) {
            console.log('unhealthy because placeWords is falsey');
            this.healthy = false;
        }
    }

    placeFirstWord(word) {
        let possibleStartPositions = [];
        let possibleEndPositions = [];

        // Add possible starting positions (edges but not corners)
        for (let i = 1; i < this.cols - 1; i++) {
            possibleStartPositions.push({ x: i, y: 0 }); // Top edge
            possibleStartPositions.push({ x: i, y: this.rows - 1 }); // Bottom edge
        }
        for (let i = 1; i < this.rows - 1; i++) {
            possibleStartPositions.push({ x: 0, y: i }); // Left edge
            possibleStartPositions.push({ x: this.cols - 1, y: i }); // Right edge
        }

        // Add possible ending positions (opposite edges but not corners)
        for (let i = 1; i < this.cols - 1; i++) {
            possibleEndPositions.push({ x: i, y: 0 }); // Top edge
            possibleEndPositions.push({ x: i, y: this.rows - 1 }); // Bottom edge
        }
        for (let i = 1; i < this.rows - 1; i++) {
            possibleEndPositions.push({ x: 0, y: i }); // Left edge
            possibleEndPositions.push({ x: this.cols - 1, y: i }); // Right edge
        }

        const start = shuffle(possibleStartPositions)[0];
        let directionBias = { x: 0, y: 0 };
        if (start.x === 0) directionBias.x = 1;
        if (start.x === this.cols - 1) directionBias.x = -1;
        if (start.y === 0) directionBias.y = 1;
        if (start.y === this.rows - 1) directionBias.y = -1;

        let path = this.grid.findPath(start, word, [], null, null, directionBias);
        if (path.length > 0) {
            if (this.grid.placeWord(word, path)) {
                return true;
            }
        }

        console.error('Unable to place first word "' + word + '"');
        return false;
    }

    placeWords(words) {
        const nodeGroups = this.grid.remainingNodeGroups();
        for (let i = 0; i < nodeGroups.length; i++) {
            const group = nodeGroups[i];
            const wordCombosThatFit = findWordCombinations(words, group.length);
            if (wordCombosThatFit.length > 0) {
                const wordsThatFit = wordCombosThatFit[0];
                const wordMash = wordsThatFit.join('');
                const shuffledGroup = shuffle(group);
                const wordWasPlaced = shuffledGroup.some((coords) => {
                    let path = this.grid.findPath(coords, wordMash);
                    if (!path || path.length === 0) {
                        return false;
                    }
                    // Break up the word mash and split the path
                    let k = 0;
                    for (let j = 0; j < wordsThatFit.length; j++) {
                        const word = wordsThatFit[j];
                        const partialPath = path.slice(k, word.length + k);
                        if (!this.grid.placeWord(word, partialPath)) {
                            return false; // This should never be hit, and not sure if returning false is what we want here. It could have already placed a word or two before failing...
                        }
                        k = k + word.length;
                    }
                    // remove words from the pool
                    words = words.filter((w) => !wordsThatFit.includes(w));
                    this.words = this.words.concat(wordsThatFit);
                    return true;
                });
                if (!wordWasPlaced) {
                    console.error('Unable to find path for word mash ' + wordMash);
                    return false;
                }
            } else {
                console.error('Unable to find words that fit length ' + group.length);
                return false;
            }
        }
        return true;
    }
}
