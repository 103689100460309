import './Node.scss';

function Node(props) {
    const handleMouseDown = () => {
        props.handleMouseDown(props.node);
    };
    const handleMouseHover = () => {
        props.handleMouseHover(props.node);
    };
    return (
        <div className="Node">
            <div
                data-node-key={'x' + props.node.x + 'y' + props.node.y}
                className={
                    'inner' +
                    (props.node.isSelected ? ' selected' : '') +
                    (props.node.isRevealed ? ' revealed' : '') +
                    (!props.node.prev ? ' first-word' : '')
                }
                onMouseDown={handleMouseDown}
                onMouseEnter={handleMouseHover}
            >
                <span data-node-key={'x' + props.node.x + 'y' + props.node.y}>{props.node.letter}</span>
            </div>
        </div>
    );
}

export default Node;
