import { useState, useEffect } from 'react';
import { shuffle } from './engine/helpers';
import GameEngine from './engine/GameEngine';
import StopWatch from './stopwatch/StopWatch';
import ShareModal from './ShareModal';
import Node from './Node';
import './SearchleGame.scss';
import { ALL_DIRECTIONS, SMALLEST_WORD_LENGTH } from './engine/constants';
// import { getSynonyms } from './data/thesaurus';

const highlightPaddingTop = 41;
const highlightPaddingLeft = 44;
const nodeSpacingHoriz = 48;
const nodeSpacingVert = 60;
const highlightColorsUnique = ['#2f9c84', '#2f6d9c', '#9c2f91', '#2f349c', '#9c602f', '#9c972f', '#2f9c3e'];
const highlightColors = [...highlightColorsUnique].concat([...highlightColorsUnique]);

function SearchleGame() {
    // const [categories, setCategories] = useState({});
    // const [words, setWords] = useState([]);
    const [game, setGame] = useState({});
    const [isActive, setIsActive] = useState(null);
    const [time, setTime] = useState(0);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [mouseX, setMouseX] = useState(null);
    const [mouseY, setMouseY] = useState(null);
    const [nodeState, setNodeState] = useState({});
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectedLetters, setSelectedLetters] = useState('');
    const [selectedHighlight, setSelectedHighlight] = useState('');
    const [wordsFound, setWordsFound] = useState([]);
    const [wordsFoundNodes, setWordsFoundNodes] = useState([]);
    const [wordsFoundHighlights, setWordsFoundHighlights] = useState([]);
    const [gameOver, setGameOver] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchAndParseThesaurusAsync = async () => {
            try {
                // Moby Thesaurus II
                // const response = await fetch(`${process.env.PUBLIC_URL}/mthesaur.txt`);
                // const text = await response.text();

                // Example: Fetch words for the 'fruit' category
                // const words = getSynonyms(text, 'games');
                // console.log('words', words);
                // setWords(words);

                // 3000 Most Common Words
                const response = await fetch(`${process.env.PUBLIC_URL}/commonWordsByLength.json`);
                const wordsByLength = await response.json();
                const words = shuffle(wordsByLength['4'])
                    .slice(0, 5)
                    .concat(shuffle(wordsByLength['5']).slice(0, 5))
                    .concat(shuffle(wordsByLength['6']).slice(0, 5))
                    .concat(shuffle(wordsByLength['7']).slice(0, 5))
                    .concat(shuffle(wordsByLength['8']).slice(0, 5))
                    .sort((a, b) => b.length - a.length);

                if (words.length > 0) {
                    let game = new GameEngine(5, 6, words);

                    // Make sure the game is healthy (no question marks)
                    let attempts = 0;
                    while (!game.healthy && attempts < 10) {
                        console.log('Unhealthy game, trying again...');
                        game = new GameEngine(5, 6, words);
                        attempts++;
                    }
                    if (!game.healthy) {
                        console.error('Unable to load a healthy game after ' + attempts + ' attempts.');
                    }

                    // console.log('first word = ' + game.firstWord);
                    // console.log('words', game.words);
                    game.grid.printGrid();
                    setGame(game);

                    // Generate states for the nodes
                    let newNodeState = {};
                    game.grid.grid.map((row) => {
                        row.map((node) => {
                            newNodeState['x' + node.x + 'y' + node.y] = {
                                ...node,
                                isSelected: false,
                                isRevealed: false,
                            };
                        });
                    });
                    setNodeState(newNodeState);
                } else {
                    console.error('No words found!');
                }
            } catch (error) {
                console.error('Error fetching or parsing thesaurus:', error);
            }
        };
        fetchAndParseThesaurusAsync();
    }, []);

    useEffect(() => {
        if (selectedNodes.length > 0) {
            // Add letters to selection
            const letters = selectedNodes.map((n) => n.letter).join('');
            setSelectedLetters(letters);

            // Calculate SVG path
            let pathPoints = selectedNodes
                .map(
                    (n) =>
                        n.x * nodeSpacingHoriz +
                        highlightPaddingLeft +
                        ' ' +
                        (n.y * nodeSpacingVert + highlightPaddingTop),
                    // +'L',
                )
                .join(' ');
            if (selectedNodes.length === 1) {
                // For only one point, it needs to go to itself or it won't show up at all
                pathPoints = pathPoints + ' ' + pathPoints;
            }
            const highlight = 'M ' + pathPoints;
            setSelectedHighlight(highlight);
        } else {
            setSelectedLetters('');
            setSelectedHighlight('');
        }
    }, [selectedNodes]);

    useEffect(() => {
        // if (wordsFoundNodes.length > 0) {
        let highlights = [];
        wordsFoundNodes.map((nodes, i) => {
            const highlight = {
                color: highlightColors[i],
                d:
                    'M ' +
                    nodes
                        .map(
                            (n) =>
                                n.x * nodeSpacingHoriz +
                                highlightPaddingLeft +
                                ' ' +
                                (n.y * nodeSpacingVert + highlightPaddingTop),
                            //+ 'L',
                        )
                        .join(' '),
            };
            highlights.push(highlight);
        });
        setWordsFoundHighlights(highlights);
        // }
    }, [wordsFoundNodes]);

    const handleStartResume = () => {
        if (isActive === null) {
            // First run
            setMessage('Begin!');
        }
        setIsActive(true);
    };

    const handlePause = () => {
        setIsActive(false);
        setMessage('');
    };

    const selectNode = (node) => {
        if (!node.isSelected && !node.isRevealed) {
            if (selectedNodes.length > 0) {
                // Only allow nodes to be selected in valid directions
                const latestNode = selectedNodes[selectedNodes.length - 1];
                if (
                    !ALL_DIRECTIONS.some((dir) => {
                        const newX = latestNode.x + dir.x;
                        const newY = latestNode.y + dir.y;
                        return node.x === newX && node.y === newY;
                    })
                ) {
                    return false;
                }
            }

            // Activate the node
            const nodeKey = 'x' + node.x + 'y' + node.y;
            updateNode(nodeKey, { isSelected: true });

            // Add letter to selection
            addNodeToSelection(node);
        }
    };

    const updateNode = (key, changes) => {
        let newNodeState = { ...nodeState };
        newNodeState[key] = { ...newNodeState[key], ...changes };
        setNodeState(newNodeState);
    };

    const submitLetters = () => {
        if (selectedLetters.length > 0) {
            let wordFound = false;
            if (selectedLetters.length >= SMALLEST_WORD_LENGTH) {
                if (game.words.includes(selectedLetters)) {
                    if (
                        !selectedNodes.some((node, i) => {
                            // Returning true means the word is not using the correct nodes
                            if (node.next) {
                                // All we need to do is verify that the next node is one of the selected nodes.
                                // If we try to match the exact order, it will be confusing to the player in certain circumstances.
                                // For example, when there is a repeated letter in a word, it shouldn't matter what order you choose them in.
                                // Another example is for palindromes (like RACECAR).
                                if (!selectedNodes.some((n) => node.next.x === n.x && node.next.y === n.y)) {
                                    // console.error('next node is not part of selection', node, selectedNodes);
                                    return true;
                                }
                            }
                            return false;
                        })
                    ) {
                        // Word is correct!
                        wordFound = true;
                        const newWordsFound = [...wordsFound].concat([selectedLetters]).sort();
                        setWordsFound(newWordsFound);
                        setWordsFoundNodes(wordsFoundNodes.concat([selectedNodes]));
                        setMessage('Correct! Good Job!');

                        // Check if all words are found
                        if (JSON.stringify(game.words.sort()) === JSON.stringify(newWordsFound)) {
                            // End the game!
                            setMessage('Game over!');
                            setGameOver(true);
                        }
                    } else {
                        setMessage('Very close!');
                    }
                } else {
                    setMessage('Sorry, not correct.');
                }
            } else {
                setMessage('Word too short.');
            }
            resetNodes(wordFound);
        }
    };

    const addNodeToSelection = (node) => {
        const newSelection = [...selectedNodes].concat([node]);
        setSelectedNodes(newSelection);
    };

    const resetNodes = (reveal = false) => {
        // Reset nodes (and set revealed if successful)
        let newNodeState = { ...nodeState };
        for (const [key, _] of Object.entries(newNodeState)) {
            if (newNodeState[key].isSelected) {
                newNodeState[key] = { ...newNodeState[key], isSelected: false };
                if (reveal) {
                    // Set nodes to revealed
                    newNodeState[key].isRevealed = true;
                }
            }
        }
        setNodeState(newNodeState);
        setSelectedNodes([]);
    };

    const handleMouseDown = (e) => {
        setMouseX(e.pageX);
        setMouseY(e.pageY);
        setIsMouseDown(true);
    };

    const handleMouseMove = (e) => {
        if (isMouseDown) {
            const diffX = Math.abs(e.pageX - mouseX);
            const diffY = Math.abs(e.pageY - mouseY);

            if (diffX > 6 || diffY > 6) {
                setIsDragging(true);
            }
        }
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
        if (isDragging) {
            setIsDragging(false);

            // Submit the letters
            submitLetters();
        }
    };

    const handleTouchStart = (e) => {
        setMouseX(e.touches[0].pageX);
        setMouseY(e.touches[0].pageY);
        setIsMouseDown(true);
        activateTouchTarget(e);
    };

    const handleTouchMove = (e) => {
        if (isMouseDown) {
            let newIsDragging = isDragging;
            const touch = e.touches[0];
            const diffX = Math.abs(touch.pageX - mouseX);
            const diffY = Math.abs(touch.pageY - mouseY);

            if (diffX > 6 || diffY > 6) {
                newIsDragging = true;
                setIsDragging(newIsDragging);
            }

            if (newIsDragging) {
                activateTouchTarget(e);
            }
        }
    };

    const activateTouchTarget = (e) => {
        // Check for node hovering (similar to handleNodeMouseHover but for touch controls)
        const touch = e.touches[0];
        const target = document.elementFromPoint(touch.pageX, touch.pageY);
        if (target && target.dataset.nodeKey) {
            const key = target.dataset.nodeKey;
            const node = nodeState[key];
            if (!node.isSelected) {
                // Select the node
                selectNode(node);
            }
        }
    };

    const handleTouchEnd = () => {
        setIsMouseDown(false);
        if (isDragging) {
            setIsDragging(false);

            // Submit the letters
            submitLetters();
        }
    };

    const handleNodeMouseDown = (node) => {
        if (node.isSelected) {
            // Submit the letters
            submitLetters();
        } else {
            // Activate the node
            selectNode(node);
        }
    };

    const handleNodeMouseHover = (node) => {
        if (isDragging) {
            if (!node.isSelected) {
                // Activate the node
                selectNode(node);
            }
        }
    };

    return (
        <div
            className="SearchleGame"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <ShareModal show={gameOver} time={time}></ShareModal>
            <div className="feedback-text">{selectedLetters || message}</div>
            {isActive && (
                <div id="SearchleGame-canvas">
                    {game.grid &&
                        game.grid.grid.map((row, rowI) => (
                            <div className={'row row-' + rowI} key={'row row-' + rowI}>
                                {row.map((node, colI) => (
                                    <div className={'col col-' + colI} key={'col col-' + colI}>
                                        <Node
                                            className="letter"
                                            node={nodeState['x' + colI + 'y' + rowI]}
                                            handleMouseDown={handleNodeMouseDown}
                                            handleMouseHover={handleNodeMouseHover}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="40" fill="none">
                            {wordsFoundHighlights &&
                                wordsFoundHighlights.map((highlight, i) => (
                                    <path
                                        stroke={highlight.color}
                                        d={highlight.d}
                                        key={'words-found-highlight-' + i}
                                    ></path>
                                ))}
                            {selectedHighlight && (
                                <path stroke="#532f9c" d={selectedHighlight} key="selected-highlight"></path>
                            )}
                        </g>
                    </svg>
                </div>
            )}
            {!isActive && (
                <div id="SearchleGame-canvas">
                    <div className="instructions">
                        <h4>How to play:</h4>
                        <ol>
                            <li>Click Start to begin.</li>
                            <li>
                                Search for words in the puzzle.
                                <div>
                                    <i>Words start with the gray letters.</i>
                                </div>
                                <div className="sub">
                                    Note: Words can go in any direction, <br />
                                    even diagonally and backwards!
                                </div>
                            </li>
                            <li>
                                When you've found all of the words, <br />
                                you win!
                            </li>
                        </ol>
                    </div>
                </div>
            )}
            <StopWatch
                time={time}
                setTime={setTime}
                gameOver={gameOver}
                handleStart={handleStartResume}
                handlePause={handlePause}
                handleResume={handleStartResume}
            />
        </div>
    );
}

export default SearchleGame;
