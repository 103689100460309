import { useRef } from 'react';
import './Home.scss';
import AppLinks from '../components/www/AppLinks/AppLinks';
import GameBox from '../components/www/GameBox/GameBox';
import usePageTitle from '../hooks/usePageTitle';

function Home() {
    usePageTitle('kilsch.dev - a private space');

    const commandInputRef = useRef(null);

    const focusChild = () => {
        commandInputRef.current && commandInputRef.current.focus();
    };

    const kilschSrc = `${process.env.PUBLIC_URL}/kilsch.svg`;

    return (
        <div className="Home" onClick={focusChild}>
            <main className="Home-main">
                <img src={kilschSrc} className="App-logo" alt="<kilsch/>" />
                <AppLinks />
                <GameBox commandInputRef={commandInputRef} />
            </main>
        </div>
    );
}

export default Home;
