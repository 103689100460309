export const ALL_DIRECTIONS = [
    { x: 1, y: 0 }, // Right
    { x: 0, y: 1 }, // Down
    { x: -1, y: 0 }, // Left
    { x: 0, y: -1 }, // Up
    { x: 1, y: 1 }, // Down-Right
    { x: -1, y: 1 }, // Down-Left
    { x: 1, y: -1 }, // Up-Right
    { x: -1, y: -1 }, // Up-Left
];

export const SMALLEST_WORD_LENGTH = 4;
