import './GameOutput.scss';

function GameOutput(props) {
    return (
        <div className="Game-output">
            {props.messages.map((msg, index) => {
                const sharedClasses = [
                    msg.isUser ? 'user-message' : 'system-message',
                    `message-type-${msg.messageType}`,
                ];
                let msgText = msg.text;
                const msgVars = msgText.match(/\{\{(.*?)\}\}/g); // looks like: ["{{name}}","{{age}}"]
                if (msgVars)
                    msgVars.forEach(
                        (varKey) =>
                            (msgText = msgText.replaceAll(varKey, props.gameVars[varKey.replace(/[{}]+/g, '')])),
                    );
                return (
                    <div
                        key={'output-ms-' + index}
                        className={['Game-output-message-wrapper', ...sharedClasses].join(' ')}
                    >
                        <div className={['Game-output-message', ...sharedClasses].join(' ')}>{msgText}</div>
                    </div>
                );
            })}
        </div>
    );
}

export default GameOutput;
