import Node from './Node';
import { shuffle, biasDirections } from './helpers';
import { ALL_DIRECTIONS, SMALLEST_WORD_LENGTH } from './constants';

export default class Grid {
    constructor(cols, rows) {
        this.rows = rows;
        this.cols = cols;
        this.grid = Array.from({ length: rows }, () => Array(cols).fill(null));
        for (let y = 0; y < rows; y++) {
            for (let x = 0; x < cols; x++) {
                this.grid[y][x] = new Node(this.grid, { x, y }, '?');
            }
        }
    }

    cloneGrid(grid = null) {
        if (!grid) grid = this.grid;
        let clonedGrid = Array.from({ length: this.rows }, () => Array(this.cols).fill(null));

        // Create new Nodes
        for (let y = 0; y < this.rows; y++) {
            for (let x = 0; x < this.cols; x++) {
                const originalNode = grid[y][x];
                clonedGrid[y][x] = new Node(clonedGrid, { x, y }, originalNode.letter);
                clonedGrid[y][x].visited = originalNode.visited;
            }
        }

        // Set next/prevs
        for (let y = 0; y < this.rows; y++) {
            for (let x = 0; x < this.cols; x++) {
                const originalNode = grid[y][x];
                clonedGrid[y][x].prev = originalNode.prev ? grid[originalNode.prev.y][originalNode.prev.x] : null;
                clonedGrid[y][x].next = originalNode.next ? grid[originalNode.next.y][originalNode.next.x] : null;
            }
        }
        return clonedGrid;
    }

    setupTestGrid() {
        // Add first word manually to make the test consistent and avoid bugs
        // const jsPath = [
        //     { x: 0, y: 2 },
        //     { x: 1, y: 1 },
        //     { x: 2, y: 0 },
        //     { x: 2, y: 1 },
        //     { x: 2, y: 2 },
        //     { x: 3, y: 1 },
        //     { x: 3, y: 0 },
        //     { x: 4, y: 1 },
        //     { x: 4, y: 2 },
        //     { x: 5, y: 3 },
        // ];
        // this.placeWord('javascript', jsPath);
        const jsPath = [
            { x: 0, y: 3 },
            { x: 1, y: 2 },
            { x: 2, y: 1 },
            { x: 2, y: 2 },
            { x: 2, y: 3 },
            { x: 3, y: 2 },
            { x: 3, y: 1 },
            { x: 4, y: 2 },
            { x: 4, y: 3 },
            { x: 5, y: 4 },
        ];
        this.placeWord('javascript', jsPath);
        // const helloPath = [
        //     { x: 0, y: 0 },
        //     { x: 1, y: 1 },
        //     { x: 2, y: 2 },
        //     { x: 3, y: 1 },
        //     { x: 4, y: 0 },
        // ];
        // this.placeWord('hello', helloPath);
        // const worldPath = [
        //     { x: 3, y: 3 },
        //     { x: 3, y: 4 },
        //     { x: 4, y: 4 },
        //     { x: 4, y: 5 },
        //     { x: 5, y: 5 },
        // ];
        // this.placeWord('world', worldPath);
        // const helloPath = [
        //     { x: 0, y: 0 },
        //     { x: 0, y: 1 },
        //     { x: 0, y: 2 },
        //     { x: 1, y: 1 },
        //     { x: 2, y: 0 },
        // ];
        // this.placeWord('hello', helloPath);
    }

    runTest() {
        const groups = this.remainingNodeGroups();
        console.log(groups);
    }

    remainingNodes(grid = null) {
        if (!grid) grid = this.grid;
        return grid.flat().filter((node) => !node.visited);
    }

    remainingNodeGroups(grid = null) {
        if (!grid) grid = this.grid;
        let remainingNodes = this.remainingNodes(grid);
        let remainingGroups = [];
        while (remainingNodes.length > 0) {
            const node = remainingNodes.pop();
            // Collect all nodes in the path
            const group = this.traversePath(node.coords(), grid);
            remainingGroups.push(group);

            // remove all nodes from this group from the remaining nodes
            const groupIds = group.map((n) => 'x' + n.x + 'y' + n.y);
            remainingNodes = remainingNodes.filter((n) => !groupIds.includes('x' + n.x + 'y' + n.y));
        }

        // This returns an array of coords (like a path), NOT nodes
        return remainingGroups;
    }

    traversePath(coords, grid = null, clonedGrid = null) {
        if (!clonedGrid) clonedGrid = this.cloneGrid(grid);
        if (!this.isValidMove(coords, null, clonedGrid)) return [];

        // Visit the current coords
        clonedGrid[coords.y][coords.x].visit();

        // Generate the return array
        let path = [coords];

        // Shuffle the directions to randomize the traversal
        const shuffledDirections = shuffle([...ALL_DIRECTIONS]);

        // Travel in shuffled, valid directions
        for (let { x: dx, y: dy } of shuffledDirections) {
            const newCoords = { x: coords.x + dx, y: coords.y + dy };
            if (this.isValidMove(newCoords, coords, clonedGrid)) {
                path = path.concat(this.traversePath(newCoords, grid, clonedGrid));
            }
        }

        return path;
    }

    // Utility function to check if a move is within the grid and not yet visited
    isValidMove(to, from = null, grid = null, remainingLetters = 0) {
        if (!grid) grid = this.grid;

        // Check if the node is out of bounds
        if (to.x < 0 || to.x >= this.cols || to.y < 0 || to.y >= this.rows) {
            return false;
        }

        // Check if the node is already visited/filled
        if (grid[to.y][to.x].visited) {
            return false;
        }

        // Check if the path will cross itself or another path
        if (from) {
            // It will only cross if it is diagonal.
            // If X and Y both change, then it is a diagonal move
            let crossNode1;
            let crossNode2;
            const deltaX = to.x - from.x;
            const deltaY = to.y - from.y;

            if (deltaX !== 0 && deltaY !== 0) {
                const crossX = deltaX > 0 ? -1 : 1;
                const crossY = deltaY > 0 ? -1 : 1;

                if (grid[to.y][to.x + crossX].visited && grid[to.y + crossY][to.x].visited) {
                    crossNode1 = grid[to.y][to.x + crossX];
                    crossNode2 = grid[to.y + crossY][to.x];

                    if (
                        (crossNode1.next && crossNode1.next.x === crossNode2.x && crossNode1.next.y === crossNode2.y) ||
                        (crossNode1.prev && crossNode1.prev.x === crossNode2.x && crossNode1.prev.y === crossNode2.y)
                    ) {
                        return false;
                    }
                }
            }
        }

        // Check if the move will cause sections that are too small
        if (remainingLetters > 0) {
            // Clone the grid and make the move, then compare node group counts
            let clonedGrid = this.cloneGrid(grid);
            // const remainingGroupsBefore = this.remainingNodeGroups(clonedGrid);
            clonedGrid[to.y][to.x].save('!', null, from); // String is randomly chosen, since this will be discarded
            const remainingGroupsAfter = this.remainingNodeGroups(clonedGrid);
            const invalidGroups = remainingGroupsAfter.filter((g) => g.length < SMALLEST_WORD_LENGTH);
            // .sort((a, b) => a.length - b.length);

            // // If there are more sections than before, we've got a problem
            // if (remainingGroupsBefore.length < remainingGroupsAfter.length) {
            //     // UNLESS all groups are at least the size of the minimum word
            //     // which would be caught by the next verification
            //     return false;
            // }

            // If the current section is left with too few remaining nodes, we've got a problem
            if (invalidGroups.length > 0) {
                const group = invalidGroups[0];
                // console.log('Beep Boop, preventing disaster by disallowing move to ' + JSON.stringify(to));
                // this.printGrid(clonedGrid);
                // console.log('group.length = ' + group.length, group);
                // console.log('remainingLetters = ' + remainingLetters);
                // See if the current word will fill the remaining space
                if (group.length !== remainingLetters - 1) {
                    return false;
                }
            }
        }

        return true;
    }

    findPath(coords, word, path = [], prev = null, grid = null, directionBias = false) {
        if (word.length === 0) return true;
        if (!grid) grid = this.cloneGrid();
        const letter = word[0];
        const remainingWord = word.substring(1);

        // Temporarily update node with new data
        grid[coords.y][coords.x].save(letter, null, prev);

        // Add coords to path (might be popped out later, if it doesn't work)
        path.push({ x: coords.x, y: coords.y });

        // If this was the final letter, we're done!
        if (remainingWord.length === 0) return true;

        // Shuffle the directions to randomize the traversal
        let shuffledDirections = shuffle([...ALL_DIRECTIONS]);

        // Make sure first word gets to the other edge
        if (directionBias) {
            shuffledDirections = biasDirections(
                coords,
                remainingWord,
                directionBias,
                this.cols,
                this.rows,
                shuffledDirections,
            );
        }

        // Try all possible directions
        for (let { x: dx, y: dy } of shuffledDirections) {
            const newX = coords.x + dx;
            const newY = coords.y + dy;

            if (this.isValidMove({ x: newX, y: newY }, { x: coords.x, y: coords.y }, grid, remainingWord.length)) {
                if (
                    this.findPath(
                        { x: newX, y: newY },
                        remainingWord,
                        path,
                        grid[coords.y][coords.x],
                        grid,
                        directionBias,
                    )
                ) {
                    return path; // Actual return to caller
                }
            }
        }

        // If no valid path is found, revert the node
        grid[coords.y][coords.x].reset();
        path.pop();
        return false;
    }

    placeWord(word, path) {
        if (word.length !== path.length) return false;
        for (let i = 0; i < word.length; i++) {
            const letter = word[i];
            const coords = path[i];
            const prevCoords = i > 0 ? path[i - 1] : null;
            const nextCoords = i < path.length ? path[i + 1] : null;
            this.grid[coords.y][coords.x].save(
                letter,
                nextCoords ? this.grid[nextCoords.y][nextCoords.x] : null,
                prevCoords ? this.grid[prevCoords.y][prevCoords.x] : null,
            );
        }
        return true;
    }

    printGrid(grid = null) {
        if (!grid) grid = this.grid;
        for (let row of grid) {
            console.log(row.map((node) => (node ? node.letter : '.')).join(' '));
        }
    }

    printGridVisited(grid = null) {
        if (!grid) grid = this.grid;
        for (let row of grid) {
            console.log(
                row.map((n) => (n.visited ? '!' : '.')).join(' ') + '    ' + row.map((n) => n.letter).join(' '),
            );
        }
    }
}
