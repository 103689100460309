// Shuffle an array (Fisher-Yates shuffle)
export function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function biasDirections(coords, remainingWord, directionBias, cols, rows, directions) {
    // Remove option to go completely backwards
    if (directionBias.x === -1) {
        // Going left
        if (remainingWord.length === coords.x) {
            // Only allow left
            return directions.filter((d) => d.x === -1);
        } else if (remainingWord.length > 1 && remainingWord.length - 1 === coords.x) {
            // Remove right
            return directions.filter((d) => d.x !== 1);
        }
    } else if (directionBias.x === 1) {
        // Going right
        if (remainingWord.length === cols - coords.x - 1) {
            // Only allow right
            return directions.filter((d) => d.x === 1);
        } else if (remainingWord.length > 1 && remainingWord.length - 1 === cols - coords.x - 1) {
            // Remove left
            return directions.filter((d) => d.x !== -1);
        }
    } else if (directionBias.y === -1) {
        // Going up
        if (remainingWord.length === coords.y) {
            // Only allow up
            return directions.filter((d) => d.y === -1);
        } else if (remainingWord.length > 1 && remainingWord.length - 1 === coords.y) {
            // Remove down
            return directions.filter((d) => d.y !== 1);
        }
    } else if (directionBias.y === 1) {
        // Going down
        if (remainingWord.length === rows - coords.y - 1) {
            // Only allow down
            return directions.filter((d) => d.y === 1);
        } else if (remainingWord.length > 1 && remainingWord.length - 1 === rows - coords.y - 1) {
            // Remove up
            return directions.filter((d) => d.y !== -1);
        }
    } else {
        console.error('Invalid direction bias.');
    }
    return directions;
}

// Recursively find a combination of words that fits a specified total length
export function findWordCombinations(words, n) {
    let result = [];

    function backtrack(startIndex, currentCombination, currentLength) {
        if (currentLength === n) {
            result.push([...currentCombination]);
            return;
        }

        if (currentLength > n) {
            return;
        }

        for (let i = startIndex; i < words.length; i++) {
            currentCombination.push(words[i]);
            backtrack(i + 1, currentCombination, currentLength + words[i].length);
            currentCombination.pop();
        }
    }

    backtrack(0, [], 0);
    return result;
}
