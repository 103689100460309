import SearchleGame from '../components/searchle/SearchleGame';
import usePageTitle from '../hooks/usePageTitle';
import './Searchle.scss';

function Searchle() {
    usePageTitle('Searchle! A word search game.');
    const logoSrc = `${process.env.PUBLIC_URL}/Searchle-logo.png`;

    return (
        <div className="Searchle">
            <main className="Searchle-main">
                <img src={logoSrc} className="App-logo" alt="Searchle - A word search game" width="180px" />
                <SearchleGame />
            </main>
        </div>
    );
}

export default Searchle;
