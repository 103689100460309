import usePageTitle from '../hooks/usePageTitle';
import './NotFound.scss';

function NotFound() {
    usePageTitle('kilsch.dev - 404');

    const kilschSrc = `${process.env.PUBLIC_URL}/kilsch.svg`;

    return (
        <div className="NotFound">
            <main className="NotFound-main">
                <img src={kilschSrc} className="App-logo" alt="<kilsch/>" />
                <br />
                Not found (404)
            </main>
        </div>
    );
}

export default NotFound;
