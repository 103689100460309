import { Outlet, Link } from 'react-router-dom';
import './Layout.scss';

const Layout = () => {
    return (
        <div className="Layout">
            <nav>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/searchle">Searchle</Link>
                    </li>
                </ul>
            </nav>

            <Outlet />
        </div>
    );
};

export default Layout;
