// Action Parsing

export function isAction({ actionType, actionTarget, inputCommand, actionCommands, strict = false }) {
    inputCommand = inputCommand.toLowerCase();

    // Check for any
    if (actionType === 'any') return true;
    if (actionType === 'input') return true;

    if (!actionCommands) actionCommands = [];
    if (actionType !== 'custom') {
        // Greeting
        if (actionType === 'greeting') {
            actionCommands = actionCommands = ['hello', 'hi', 'yo', 'hey', 'whats up', 'what up', "what's up"];

            // Yes
        } else if (actionType === 'yes') {
            actionCommands = actionCommands = ['yes', 'affirmative', 'sure', 'ok'];

            // No
        } else if (actionType === 'no') {
            actionCommands = actionCommands = ['no', 'negative', 'negatory', 'nope', 'naw'];

            // Move
        } else if (actionType === 'move') {
            actionCommands = actionCommands = ['move', 'walk', 'go'];

            // Inspect
        } else if (actionType === 'inspect' || actionType === 'look') {
            actionCommands = actionCommands = ['inspect', 'look', 'check', 'peer', 'glance'];

            // Take
        } else if (actionType === 'take') {
            actionCommands = actionCommands = ['take', 'grab', 'obtain', 'get', 'pick up'];

            // Use
        } else if (actionType === 'use') {
            actionCommands = actionCommands = ['use'];

            // Touch
        } else if (actionType === 'touch') {
            actionCommands = actionCommands = ['touch', 'press'];

            // Smell
        } else if (actionType === 'smell') {
            actionCommands = actionCommands = ['smell', 'sniff'];

            // Taste
        } else if (actionType === 'taste') {
            actionCommands = actionCommands = ['taste', 'lick', 'eat', 'bite', 'consume'];

            // Equip
        } else if (actionType === 'equip') {
            actionCommands = actionCommands = ['equip', 'put on', 'wear', 'put'];

            // Open
        } else if (actionType === 'open') {
            actionCommands = actionCommands = ['open'];

            // Get up
        } else if (actionType === 'getup') {
            actionCommands = actionCommands = ['get up', 'stand up', 'sit up', 'rise', 'stand'];
        }
    }

    // If strict, match entire command to each option
    if (strict) {
        return actionCommands.includes(inputCommand);
    }

    // Check if inputCommand matches part of any action commands
    for (var i = 0; i < actionCommands.length; i++) {
        if (inputCommand.includes(actionCommands[i])) {
            // If target exists, check for target also
            if (typeof actionTarget === 'string') {
                actionTarget = [actionTarget];
            }
            if (typeof actionTarget === 'object') {
                var commandTarget = inputCommand.substring(inputCommand.indexOf(actionCommands[i]) + 1);
                for (var j = 0; j < actionTarget.length; j++) {
                    if (commandTarget.includes(actionTarget[j])) {
                        return true;
                    }
                }
                return false;
            }

            return true;
        }
    }
    return false;
}
