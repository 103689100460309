export default class Node {
    constructor(grid, coords, letter) {
        this.grid = grid;
        this.x = coords.x;
        this.y = coords.y;
        this.letter = letter;
        this.next = null;
        this.prev = null;
        this.visited = false;
    }

    coords() {
        return { x: this.x, y: this.y };
    }

    visit() {
        this.visited = true;
    }

    vacate() {
        this.visited = false;
    }

    save(letter, next = null, prev = null) {
        this.visit();
        this.letter = letter.toUpperCase();
        // If next value has changed, update connected node
        if (
            (next && !this.next) ||
            (!next && this.next) ||
            (next && this.next && (next.x !== this.next.x || next.y !== this.next.y))
        ) {
            if (this.next && !next) {
                this.grid[this.next.y][this.next.x].prev = null;
                this.next = null;
            }
            if (next) {
                this.grid[next.y][next.x].prev = this;
                this.next = this.grid[next.y][next.x];
            }
        }
        if (
            (prev && !this.prev) ||
            (!prev && this.prev) ||
            (prev && this.prev && (prev.x !== this.prev.x || prev.y !== this.prev.y))
        ) {
            if (this.prev && !prev) {
                this.grid[this.prev.y][this.prev.x].next = null;
                this.prev = null;
            }
            if (prev) {
                this.grid[prev.y][prev.x].next = this;
                this.prev = this.grid[prev.y][prev.x];
            }
        }
    }

    reset() {
        this.vacate();
        this.letter = '?';
        // Remove all connections to this node
        if (this.next) {
            this.grid[this.next.y][this.next.x].prev = null;
        }
        this.next = null;
        if (this.prev) {
            this.grid[this.prev.y][this.prev.x].next = null;
        }
        this.prev = null;
    }
}
