import { useState, useEffect } from 'react';
import './StopWatch.scss';
import Timer from './Timer';
import ControlButtons from './ControlButtons';

function StopWatch(props) {
    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(true);

    useEffect(() => {
        let interval = null;

        if (isActive && isPaused === false && props.gameOver === false) {
            interval = setInterval(() => {
                props.setTime((time) => time + 10);
            }, 10);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isActive, isPaused, props.gameOver]);

    const handleStart = () => {
        setIsActive(true);
        setIsPaused(false);
        if (props.handleStart) {
            props.handleStart();
        }
    };

    const handlePause = () => {
        setIsPaused(true);
        if (props.handlePause) {
            props.handlePause();
        }
    };

    const handleResume = () => {
        setIsPaused(false);
        if (props.handleResume) {
            props.handleResume();
        }
    };

    return (
        <div className="stop-watch">
            {isActive && <Timer time={props.time} />}
            {!props.gameOver && (
                <ControlButtons
                    isActive={isActive}
                    isPaused={isPaused}
                    handleStart={handleStart}
                    handlePause={handlePause}
                    handleResume={handleResume}
                />
            )}
        </div>
    );
}

export default StopWatch;
