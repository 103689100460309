import Button from '../Button';
import Modal from '../Modal';
import './ShareModal.scss';

function ShareModal({ show, time }) {
    const timeFormatted =
        ('0' + Math.floor((time / 60000) % 60)).slice(-2) + ':' + ('0' + Math.floor((time / 1000) % 60)).slice(-2);

    const title =
        time < 30000
            ? 'Literally insane!'
            : time < 60000
            ? 'Incredible!'
            : time < 120000
            ? 'Amazing!'
            : time < 180000
            ? 'Good work!'
            : 'You did it!';

    const handleShareClick = () => {
        // Construct the SMS message
        const message = `${timeFormatted} https://kilsch.dev/searchle?time=${time}`;

        // Create the SMS URL
        const smsURL = `sms:?&body=${encodeURIComponent(message)}`;

        // Create a temporary anchor element
        const tempLink = document.createElement('a');
        tempLink.href = smsURL;

        // Append the anchor to the body
        document.body.appendChild(tempLink);

        // Simulate a click on the anchor
        tempLink.click();

        // Remove the anchor from the document
        document.body.removeChild(tempLink);
    };

    return (
        <div id="ShareModal">
            <Modal id="ShareModal" show={show} allowClose={false}>
                <h3>{title}</h3>
                <h2 class="time">{timeFormatted}</h2>
                <p>Share your time with your friends!</p>
                <div class="button-group">
                    <Button onClick={handleShareClick} isPrimary={true}>
                        Share
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default ShareModal;
