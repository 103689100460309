export default function ({ evt, setInputValue, submitCommand, commandIndex, setCommandIndex, commandHistory }) {
    if (evt.key == 'Enter') {
        // Submit command
        setInputValue(evt.target.value);
        evt.target.value = '';
        submitCommand();
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 100);
    } else if (evt.key == 'ArrowUp') {
        // Go to previous command
        if (commandIndex > 0) {
            if (commandHistory[commandIndex - 1]) setCommandIndex(commandIndex - 1);
        } else {
            // Fixes glitch with cursor positioning
            evt.target.value = '';
        }
        if (commandHistory[commandIndex]) evt.target.value = commandHistory[commandIndex];
    } else if (evt.key == 'ArrowDown') {
        // Go to next command
        if (commandIndex < commandHistory.length - 1) {
            setCommandIndex(commandIndex + 1);
            evt.target.value = commandHistory[commandIndex];
        } else {
            // Fixes glitch with cursor positioning
            evt.target.value = '';
        }
    } else {
        // Just typing
        setCommandIndex(commandHistory.length);
        setInputValue(evt.target.value);
    }
    evt.preventDefault();
}
