import { isAction } from './isAction';

export default class GameEngine {
    // Constructor props
    gameData;
    addSystemMessage;
    setMessages;
    setCommandHistory;
    enableInput;
    disableInput;
    setActiveNode;
    setGameVar;
    setGameVarStore;
    defaultDelay;

    constructor({
        gameData,
        addSystemMessage,
        setMessages,
        setCommandHistory,
        enableInput,
        disableInput,
        setActiveNode,
        setGameVar,
        setGameVarStore,
        defaultDelay,
    }) {
        this.gameData = gameData;
        this.addSystemMessage = addSystemMessage;
        this.setMessages = setMessages;
        this.setCommandHistory = setCommandHistory;
        this.enableInput = enableInput;
        this.disableInput = disableInput;
        this.setGameVar = setGameVar;
        this.setGameVarStore = setGameVarStore;
        this.setActiveNode = setActiveNode;
        this.defaultDelay = defaultDelay ?? 2000;

        console.log('GameEngine class instantiated.');
    }

    get position() {
        return this._position;
    }

    set position(pos) {
        this._position = pos;
        console.log(`==== New position: ${pos}`);
    }

    get currentNode() {
        return this._currentNode;
    }

    set currentNode(node) {
        this._currentNode = node;
        console.log('Current node has been set.', JSON.parse(JSON.stringify(node)));
    }

    reset() {
        this.disableInput();
        this.setMessages([]);
        this.setCommandHistory([]);
        this.setActiveNode(undefined);
        this.setGameVarStore({});
        this.position = '';
        console.log('Game reset.');
    }

    startGame() {
        this.reset();
        this.activateNode(this.gameData[0], 0);
        console.log('Game started.');
    }

    autoAdvance() {
        this.activateNode(this.currentNode.actions[0], 0);
    }

    sendOutput(node, callback) {
        // Check for message delay
        let addMsgDelay = 0;
        if (node.delay) {
            if (node.delay === true) {
                addMsgDelay = this.defaultDelay;
            } else {
                addMsgDelay = node.delay;
            }
        }

        setTimeout(() => {
            this.addSystemMessage(node.output, node.outputType);
            if (node.actions) {
                const firstNode = node.actions[0];
                // Enable input (if actions are available)
                if (firstNode.type === 'auto') {
                    // Auto-advance to next node
                    this.autoAdvance();
                } else if (firstNode.type === 'warp') {
                    console.log('.----=~~~`\\: WARP! :/`~~~=----.');
                } else {
                    this.enableInput();
                }
            } else {
                console.error('No node actions... what should I do?', node);
            }
            if (typeof callback === 'function') {
                callback();
            }
        }, addMsgDelay);
    }

    setCurrentNode(node, nodeIndex) {
        this.position = !this.position ? `${nodeIndex}` : `${this.position}_${nodeIndex}`;
        this.currentNode = node;
    }

    activateNode(node, nodeIndex) {
        if (!node) {
            console.error('Unable to activate node (null).', node);
            return;
        }

        // Let parent (Game) know about the active node
        this.setActiveNode(node);

        // Add the message (with or without delay)
        this.sendOutput(node);

        // If this action has children, set as current node
        if (node.actions?.length) {
            this.setCurrentNode(node, nodeIndex);
        } else {
            // If no actions, allow user to try a different command with the parent node
            this.enableInput();
        }
    }

    processCommand(inputCommand) {
        this.disableInput();
        let actionMatched = false;
        this.currentNode.actions.some((action, actionIndex) => {
            const actionTypes = action.types || [action.type];
            return actionTypes.some((actionType) => {
                // Check if the command input matches the action
                console.log(`Checking if command "${inputCommand}" matches "${actionType}" action type.`, action);
                if (
                    isAction({
                        actionType,
                        actionTarget: action.target,
                        actionCommands: action.commands,
                        inputCommand,
                        strict: !!action.strict,
                    })
                ) {
                    console.log(' - - - - - MATCH!');
                    actionMatched = true;

                    // Special actions
                    if (actionType === 'input') this.setGameVar(action.varName, inputCommand);

                    // Activate the node
                    this.activateNode(action, actionIndex);

                    return true; // break;
                }
                return false; // continue
            });
            return false;
        });

        if (!actionMatched) {
            this.invalidCommand();
        }
    }

    invalidCommand() {
        const noMatchMsgOptions = [
            'Nothing seemed to happen.',
            'Perhaps you should try something different.',
            'Nothing changed.',
            'Nothing happened. Try something else.',
            'That did nothing.',
            'Try something else, perhaps.',
            'Maybe you should do something else.',
            'Hmmm... that did nothing.',
        ];

        const randomIndex = Math.floor(Math.random() * noMatchMsgOptions.length);
        const noMatchNode = JSON.parse(JSON.stringify(this.currentNode)); // Not sure if this is necessary
        noMatchNode.output = noMatchMsgOptions[randomIndex];
        noMatchNode.outputType = 'narrative';
        noMatchNode.delay = false;
        this.sendOutput(noMatchNode);
        this.enableInput();
    }
}
