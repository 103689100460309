import Button from '../../Button';
import './ControlButtons.scss';

export default function ControlButtons(props) {
    const StartButton = (
        <Button onClick={props.handleStart} isPrimary={true}>
            Start
        </Button>
    );
    const PauseButton = (
        <Button onClick={props.handlePause} isCancel={true}>
            Pause
        </Button>
    );
    const ResumeButton = (
        <Button onClick={props.handleResume} isPrimary={true}>
            Resume
        </Button>
    );

    return (
        <div className="Control-Buttons">
            <div>{props.isActive ? (props.isPaused ? ResumeButton : PauseButton) : StartButton}</div>
        </div>
    );
}
